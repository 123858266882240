import React, { memo } from 'react'
import {
	Modal,
	VStack,
	Button,
	Heading,
	Image,
	Text,
	HStack,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
} from '@chakra-ui/react'

const ModalUpdateRsvpConfirm = memo(({ onClose, isOpen, status }) => {
	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="md">
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalBody>
					<VStack textAlign="center" spacing="1rem">
						<Image src="/assets/images/graphics/rsvp.svg" mb="1rem" alt="RSVP Status" />
						<Heading size="md" variant="hero">
							Your status for this RSVP has been updated to:
						</Heading>
						<Text size="lg" textTransform="capitalize" fontWeight="700">
							{status}
						</Text>
					</VStack>
				</ModalBody>
				<ModalFooter>
					<HStack w="full" spacing="1rem" justifyContent="center">
						<Button
							onClick={() => {
								onClose()
							}}>
							Okay
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
})

export default ModalUpdateRsvpConfirm
